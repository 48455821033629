// // Footer.js
// import React from "react";
// import styles from "./Footer.module.css";
// import logo from "./footer-logo.png";
// import facebookIcon from "./fb.png";
// import twitterIcon from "./x.png";
// import instagramIcon from "./ig.png";
// import btnLogo from "./btn-logo.png";

// const Footer = () => {
//   return (
//     <div className={styles.footer}>
//       <div className={`${styles.container} container`}>
//         <div className="row">
//           <div className={`col-md-3 col-sm-12 ${styles.center}`}>
//             <img src={logo} alt="Company Logo" className={styles.logo} />
//             <div className={styles.socialIcons}>
//               <img src={facebookIcon} alt="Facebook" />
//               <img src={twitterIcon} alt="Twitter" />
//               <img src={instagramIcon} alt="Instagram" />
//             </div>
//             <button className={styles.btn}>
//               <img src={btnLogo} alt="Logo" className={styles.logoInButton} />
//               <span className={styles.btnText}> made by Indic Lancers</span>
//             </button>
//           </div>
//           {/* <hr className={styles.hr} /> */}
//           <div className={`col-md-3 col-sm-12 ${styles.center}`}>
//             <h5 className={styles.fHeader}>Navigation</h5>
//             <ul>
//               <li>About Us</li>
//               <li>Our Services</li>
//               <li>Announcements</li>
//               <li>Our Values</li>
//               <li>Blogs</li>
//               <li>Contact Us</li>
//             </ul>
//           </div>
//           <div className={`col-md-3 col-sm-12 ${styles.center}`}>
//             <h5 className={styles.fHeader}>Company Legals</h5>
//             <ul>
//               <li>Privacy Policy</li>
//               <li>Cookie Policy</li>
//             </ul>
//           </div>
//           <div className={`col-md-3 col-sm-12 ${styles.center}`}>
//             <h5 className={styles.fHeader}>Address</h5>
//             <address>
//               1700 W Blancke St, kiyev port
//               <br />
//               south USA, Americas
//             </address>
//           </div>
//         </div>
//         <hr className={styles.horizontalHr} />
//         <p className={styles.copyRight}>
//           ©2024 Indic Arena. All rights reserved
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Footer;

// Footer.js
// Footer.js
import React, { useEffect, useState } from "react";
import styles from "./Footer.module.css";
import client, { urlFor } from "../../sanityClient";

const Footer = () => {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    client.fetch('*[_type == "footer"][0]').then((data) => {
      console.log("Fetched footer data:", data); // Debugging line
      setFooterData(data);
    });
  }, []);

  if (!footerData) return <div>Loading...</div>;

  return (
    <div className={styles.footer}>
      <div className={`${styles.container} container`}>
        <div className="row">
          <div className={`col-md-3 col-sm-12 ${styles.center}`}>
            {footerData.logo && (
              <img
                src={urlFor(footerData.logo.asset.url)}
                alt="Company Logo"
                className={styles.logo}
              />
            )}
            <div className={styles.socialIcons}>
              {footerData.socialIcons &&
                footerData.socialIcons.map((icon, index) => (
                  <img
                    key={index}
                    src={urlFor(icon.asset)}
                    alt={`Social Icon ${index + 1}`}
                  />
                ))}
            </div>
            <button className={styles.btn}>
              {footerData.buttonLogo && (
                <img
                  src={urlFor(footerData.buttonLogo)}
                  alt="Logo"
                  className={styles.logoInButton}
                />
              )}
              <span className={styles.btnText}>{footerData.buttonText}</span>
            </button>
          </div>
          <div className={`col-md-3 col-sm-12 ${styles.center}`}>
            <h5 className={styles.fHeader}>Navigation</h5>
            <ul className={styles.footerUl}>
              {footerData.navigation &&
                footerData.navigation.map((link, index) => (
                  <li key={index}>{link}</li>
                ))}
            </ul>
          </div>
          <div className={`col-md-3 col-sm-12 ${styles.center}`}>
            <h5 className={styles.fHeader}>Company Legals</h5>
            <ul className={styles.footerUl}>
              {footerData.companyLegals &&
                footerData.companyLegals.map((legal, index) => (
                  <li key={index}>{legal}</li>
                ))}
            </ul>
          </div>
          <div className={`col-md-3 col-sm-12 ${styles.center}`}>
            <h5 className={styles.fHeader}>Address</h5>
            <address>{footerData.address}</address>
          </div>
        </div>
        <hr className={styles.horizontalHr} />
        <p className={styles.copyRight}>{footerData.copyRight}</p>
      </div>
    </div>
  );
};

export default Footer;
