import React from "react";
import styles from "./AboutDetail.module.css"; // Import CSS Modules
import cardLogo from "./cardlogo.png";
const AboutUsVisionCard = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.aboutStatsCard}>
        {/* Card 1 */}
        <div className={styles.statItemCard}>
          <div className={`${styles.overlayCard} ${styles.visionCard}`}>
            <div className={styles}>
              <span className={styles.cardLogo}>
                <img src={cardLogo} />
              </span>
            </div>
            <h2 className={styles.subHeadCard}>Creative Ideas</h2>
            <p className={styles.subTextCard}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              eget accumsan augue Donec aliquam.
            </p>
          </div>
        </div>

        {/* Card 2 */}
        <div className={styles.statItemCard}>
          <div className={`${styles.overlayCard} ${styles.visionCard}`}>
            <div className={styles}>
              <span className={styles.cardLogo}>
                <img src={cardLogo} />
              </span>
            </div>
            <h2 className={styles.subHeadCard}>Creative Ideas</h2>
            <p className={styles.subTextCard}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              eget accumsan augue Donec aliquam.
            </p>
          </div>
        </div>

        {/* Card 3 */}
        <div className={styles.statItemCard}>
          <div className={`${styles.overlayCard} ${styles.visionCard}`}>
            <div className={styles}>
              <span className={styles.cardLogo}>
                <img src={cardLogo} />
              </span>
            </div>
            <h2 className={styles.subHeadCard}>Creative Ideas</h2>
            <p className={styles.subTextCard}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              eget accumsan augue Donec aliquam.
            </p>
          </div>
        </div>

        {/* Card 4 */}
        <div className={styles.statItemCard}>
          <div className={`${styles.overlayCard} ${styles.visionCard}`}>
            <div className={styles}>
              <span className={styles.cardLogo}>
                <img src={cardLogo} />
              </span>
            </div>
            <h2 className={styles.subHeadCard}>Creative Ideas</h2>
            <p className={styles.subTextCard}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              eget accumsan augue Donec aliquam.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsVisionCard;
