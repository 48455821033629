// AboutStats.js
import React from "react";
import styles from "./AboutDetail.module.css"; // Import module CSS

const AboutStats = () => {
  return (
    <section className={`${styles.aboutStats} ${styles.additionalSection}`}>
      {/* <div className={styles.sectionBadgeStat}>About Stats</div> */}
      <div className={styles.statItem}>
        <div className={styles.number}>111+</div>
        <div className={styles.text}>Successful Work</div>
      </div>
      <div className={styles.statItem}>
        <div className={styles.number}>98+</div>
        <div className={styles.text}>Team Members</div>
      </div>
      <div className={styles.statItem}>
        <div className={styles.number}>98+</div>
        <div className={styles.text}>Team Members</div>
      </div>
      <div className={styles.statItem}>
        <div className={styles.number}>98+</div>
        <div className={styles.text}>Team Members</div>
      </div>
      {/* Add more stat items as needed */}
    </section>
  );
};

export default AboutStats;
