// import React, { useState } from "react";
// import styles from "./Faq.module.css";
// import downArrow from "./down-arrow.png"; // Adjust the path as per your setup

// const Faq = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const faqs = [
//     {
//       question: "How can Indic Arena help me with my design needs?",
//       answer:
//         "At Abravic, we offer several ways to assist you with your shipment. For immediate help, you can call our customer support hotline at [Phone Number] or use the live chat feature on our website during business hours. If you prefer written communication, email us at [Email Address] or fill out the contact form on our website with your shipment details. Additionally, you can track your shipment in real-time using the tracking tool on our website by entering your tracking number.",
//     },
//     {
//       question: "How can Indic Arena help me with my design needs?",
//       answer:
//         "At Abravic, we offer several ways to assist you with your shipment. For immediate help, you can call our customer support hotline at [Phone Number] or use the live chat feature on our website during business hours. If you prefer written communication, email us at [Email Address] or fill out the contact form on our website with your shipment details. Additionally, you can track your shipment in real-time using the tracking tool on our website by entering your tracking number.",
//     },
//     {
//       question: "How can Indic Arena help me with my design needs?",
//       answer:
//         "At Abravic, we offer several ways to assist you with your shipment. For immediate help, you can call our customer support hotline at [Phone Number] or use the live chat feature on our website during business hours. If you prefer written communication, email us at [Email Address] or fill out the contact form on our website with your shipment details. Additionally, you can track your shipment in real-time using the tracking tool on our website by entering your tracking number.",
//     },
//     {
//       question: "How can Indic Arena help me with my design needs?",
//       answer:
//         "At Abravic, we offer several ways to assist you with your shipment. For immediate help, you can call our customer support hotline at [Phone Number] or use the live chat feature on our website during business hours. If you prefer written communication, email us at [Email Address] or fill out the contact form on our website with your shipment details. Additionally, you can track your shipment in real-time using the tracking tool on our website by entering your tracking number.",
//     },
//     {
//       question: "How can Indic Arena help me with my design needs?",
//       answer:
//         "At Abravic, we offer several ways to assist you with your shipment. For immediate help, you can call our customer support hotline at [Phone Number] or use the live chat feature on our website during business hours. If you prefer written communication, email us at [Email Address] or fill out the contact form on our website with your shipment details. Additionally, you can track your shipment in real-time using the tracking tool on our website by entering your tracking number.",
//     },
//     // Add more FAQs as needed...
//   ];

//   const toggleAccordion = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   return (
//     <div className={styles.faqContainer}>
//       <h1 className={styles.head}>Have a question?</h1>
//       <h1 className={styles.header}>FAQ</h1>
//       <div className={styles.accordion}>
//         {faqs.map((faq, index) => (
//           <div key={index} className={styles.faqItem}>
//             <div className={styles.questionRow}>
//               <span className={styles.question}>{faq.question}</span>
//               <button
//                 className={`btn btn-link ${styles.expandButton}`}
//                 onClick={() => toggleAccordion(index)}
//                 aria-expanded={activeIndex === index}
//               >
//                 <img
//                   src={downArrow}
//                   alt="Down Arrow"
//                   className={`${styles.arrowIcon} ${
//                     activeIndex === index ? styles.rotate : ""
//                   }`}
//                 />
//               </button>
//             </div>
//             <div
//               className={`${styles.answer} ${
//                 activeIndex === index ? styles.show : ""
//               }`}
//             >
//               {faq.answer}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Faq;

import React, { useState, useEffect } from "react";
import styles from "./Faq.module.css";
import downArrow from "./down-arrow.png"; // Adjust the path as per your setup
import client from "../../sanityClient";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      const query = '*[_type == "faq"]';
      const data = await client.fetch(query);
      setFaqs(data);
    };

    fetchFaqs();
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.faqContainer}>
      <h1 className={styles.head}>Have a question?</h1>
      <h1 className={styles.header}>FAQ</h1>
      <div className={styles.accordion}>
        {faqs.map((faq, index) => (
          <div key={index} className={styles.faqItem}>
            <div className={styles.questionRow}>
              <span className={styles.question}>{faq.question}</span>
              <button
                className={`btn btn-link ${styles.expandButton}`}
                onClick={() => toggleAccordion(index)}
                aria-expanded={activeIndex === index}
              >
                <img
                  src={downArrow}
                  alt="Down Arrow"
                  className={`${styles.arrowIcon} ${
                    activeIndex === index ? styles.rotate : ""
                  }`}
                />
              </button>
            </div>
            <div
              className={`${styles.answer} ${
                activeIndex === index ? styles.show : ""
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
