import React from "react";
import ContactUsForm from "./ContactUsForm";
import Navbar from "../Navbar/Navbar";
import Footer from "../Home/footer/Footer";
import Enquiry from "./Enquery";

const Contact = () => {
  return (
    <div>
      <Navbar />
      {/* <ContactUs /> */}
      <ContactUsForm />
      <Enquiry />
      <Footer />
    </div>
  );
};

export default Contact;
