// // src/components/Team.js

// import React from "react";
// import styles from "./Team.module.css";
// // import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
// import ig from "./ig.png";
// import fb from "./fb.png";
// import x from "./x.png";

// import placeImg from "./placeholder.png";

// const Team = () => {
//   const teamMembers = [
//     {
//       name: "John Doe",
//       position: "CEO",
//       image: placeImg,
//       social: {
//         ig: "#",
//         twitter: "#",
//         linkedin: "#",
//       },
//     },
//     {
//       name: "Jane Smith",
//       position: "CTO",
//       image: placeImg,
//       social: {
//         facebook: "#",
//         twitter: "#",
//         linkedin: "#",
//       },
//     },
//   ];

//   return (
//     <div className={`container text-center ${styles.teamContainer}`}>
//       <h1 className={`my-4 ${styles.teamHeader}`}>The Team Behind</h1>
//       {/* <h3 className={`mb-5 ${styles.subHead}`}>Meet our core team members</h3> */}
//       <div className="row justify-content-center">
//         {teamMembers.map((member, index) => (
//           <div
//             key={index}
//             className={`col-12 col-md-6 col-lg-4 ${styles.card}`}
//           >
//             <div className={`card ${styles.teamCard}`}>
//               <img
//                 src={member.image}
//                 className={`card-img-top img-fluid ${styles.cardImg}`}
//                 alt={member.name}
//               />
//               <div className={`card-body ${styles.cardBody}`}>
//                 <div className={styles.cardDetails}>
//                   <h5 className={`card-title ${styles.cardTitle}`}>
//                     {member.name}
//                   </h5>
//                   <p className={`card-text ${styles.cardText}`}>
//                     {member.position}
//                   </p>
//                 </div>
//                 <div className={styles.socialIcons}>
//                   <a
//                     href={member.social.facebook}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <img src={ig} alt="ig" />
//                     {/* <FaFacebook /> */}
//                   </a>
//                   <a
//                     href={member.social.twitter}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <img src={x} alt="x" />
//                     {/* <FaTwitter /> */}
//                   </a>
//                   <a
//                     href={member.social.linkedin}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <img src={fb} alt="fb" />
//                     {/* <FaLinkedin /> */}
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Team;

// src/components/Team.js

// src/components/Team.js

import React, { useEffect, useState } from "react";
import styles from "./Team.module.css";
import client from "../../sanityClient";

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await client.fetch(`*[_type == "teamMember"] {
          name,
          position,
          image {
            asset-> {
              url
            }
          },
          social {
            platform,
            url,
            icon {
              asset-> {
                url
              }
            }
          }
        }`);
        setTeamMembers(response);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, []);

  return (
    <div className={`container text-center ${styles.teamContainer}`}>
      <h1 className={`my-4 ${styles.teamHeader}`}>The Team Behind</h1>
      <div className="row justify-content-center">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className={`col-12 col-md-6 col-lg-4 ${styles.card}`}
          >
            <div className={`card ${styles.teamCard}`}>
              <img
                src={member.image.asset.url}
                className={`card-img-top img-fluid ${styles.cardImg}`}
                alt={member.name}
              />
              <div className={`card-body ${styles.cardBody}`}>
                <div className={styles.cardDetails}>
                  <h5 className={`card-title ${styles.cardTitle}`}>
                    {member.name}
                  </h5>
                  <p className={`card-text ${styles.cardText}`}>
                    {member.position}
                  </p>
                </div>
                {/* <div className={styles.socialIcons}>
                  {member.social.map((social, idx) => (
                    <a
                      key={idx}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={social.icon.asset.url} alt={social.platform} />
                    </a>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
