// import React from "react";
// import styles from "./AboutDetail.module.css";
// import Navbar from "../Navbar/Navbar";
// import aboutBg from "./about-bg2.png";
// import tick from "./tick.png";
// const AboutDetail = () => {
//   return (
//     <>
//       <Navbar />
//       <div className={styles.background}>
//         <div className={styles.overlay}>
//           <div className={styles.badge}>
//             <span className={styles.badgeText}>Services</span>
//           </div>
//           <h1 className="mt-2">
//             <span className={styles.subText}>
//               Creative Services For Boost <br />
//               Your Business Growth
//             </span>
//           </h1>
//         </div>
//       </div>
//       <div className={`container mt-5 ${styles.additionalSection}`}>
//         <div className="row">
//           <div className="col-lg-6 col-md-12">
//             <div className={styles.sectionBadge}>OUR VISION</div>
//             <h2 className={`mt-1 ${styles.subHead}`}>
//               Turn your ideas into reality.
//             </h2>
//             <h3 className={`mt-2 ${styles.subHeadTwo}`}>
//               Capitalize on low hanging fruit to identify a ballpark value added
//               activity beta test. Override the digital divide with additional
//               from DevOps.
//             </h3>
//             <ul className="mt-3">
//               <li className={styles.aboutLi}>
//                 <img src={tick} className={styles.tick} />
//                 Bring to the table win-win survival strategies to ensure
//                 proactive domination. At the end of the day.
//               </li>
//               <li className={styles.aboutLi}>
//                 <img src={tick} className={styles.tick} /> Capitalize on low
//                 hanging fruit to identify a ballpark value added activity to
//                 beta test override the digital.
//               </li>
//             </ul>
//           </div>
//           <div className="col-lg-6 col-md-12">
//             <img
//               src={aboutBg}
//               alt="Premium Features"
//               className={styles.image}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AboutDetail;

import React from "react";
import Navbar from "../Navbar/Navbar";
import AboutUsBanner from "./AboutUsBanner";
import AboutUsVision from "./AboutUsVision";
import Faq from "../Home/faq/Faq";
import Footer from "../Home/footer/Footer";
import AboutStats from "./AboutStat";
import AboutUsValues from "./AboutUsValues";
import AboutUsHistory from "./AboutUsHistory";

const AboutDetail = () => {
  return (
    <>
      <Navbar />
      <AboutUsBanner />
      <AboutUsVision />
      <AboutStats />
      <AboutUsValues />
      <AboutUsHistory />
      <Faq />
      <Footer />
    </>
  );
};

export default AboutDetail;
