// // Enquiry.js

// import React from "react";
// import styles from "./ContactUs.module.css";

// const Enquiry = () => {
//   return (
//     <div className={styles.enquiryContainer}>
//       <div className={styles.enquiryContent}>
//         <div className={styles.enquiryCol}>
//           <div className={styles.enquiryInner}>
//             <div className={styles.enquiryRow}>
//               <div className={styles.enquiryCol}>
//                 <h2 className={styles.enquiryHeading}>General Enquiry</h2>
//                 <div className={styles.pDiv}>
//                   <p>indicarena@gmail.com</p>
//                   <p>(001) 98654 32154</p>
//                   <p>
//                     <a href="#">www.indicarena.com</a>
//                   </p>
//                 </div>
//               </div>
//               <div className={styles.enquiryCol}>
//                 <h2 className={styles.enquiryHeading}>Don't Like Forms</h2>
//                 <div className={styles.pDiv}>
//                   <p>
//                     <a href="#">facebook.com/example</a>
//                   </p>
//                   <p>
//                     <a href="#">instagram.com/example</a>
//                   </p>
//                   <p>
//                     <a href="#">twitter.com/example</a>
//                   </p>
//                   <p>
//                     <a href="#">linkedin.com/example</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Enquiry;

import React, { useEffect, useState } from "react";
import client from "../sanityClient";
import styles from "./ContactUs.module.css";

const Enquery = () => {
  const [enquiryData, setEnquiryData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "enquiry"]{
          generalEnquiry{
            email,
            phone,
            website
          },
          socialMedia{
            facebook,
            instagram,
            twitter,
            linkedin
          }
        }`
      )
      .then((data) => setEnquiryData(data[0]))
      .catch(console.error);
  }, []);

  if (!enquiryData) {
    return <div></div>;
  }

  const { generalEnquiry, socialMedia } = enquiryData;

  return (
    <div className={styles.enquiryContainer}>
      <div className={styles.enquiryContent}>
        <div className={styles.enquiryCol}>
          <div className={styles.enquiryInner}>
            <div className={styles.enquiryRow}>
              <div className={styles.enquiryCol}>
                <h2 className={styles.enquiryHeading}>General Enquiry</h2>
                <div className={styles.pDiv}>
                  <p>{generalEnquiry.email}</p>
                  <p>{generalEnquiry.phone}</p>
                  <p>
                    <a href={generalEnquiry.website}>
                      {generalEnquiry.website}
                    </a>
                  </p>
                </div>
              </div>
              <div className={styles.enquiryCol}>
                <h2 className={styles.enquiryHeading}>Don't Like Forms</h2>
                <div className={styles.pDiv}>
                  <p>
                    <a href={socialMedia.facebook}>facebook.com/example</a>
                  </p>
                  <p>
                    <a href={socialMedia.instagram}>instagram.com/example</a>
                  </p>
                  <p>
                    <a href={socialMedia.twitter}>twitter.com/example</a>
                  </p>
                  <p>
                    <a href={socialMedia.linkedin}>linkedin.com/example</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquery;
