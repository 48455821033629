// import React from "react";
// import styles from "./AboutDetail.module.css";
// import tick from "./tick.png";

// const AboutUsVision = () => {
//   return (
//     <div className={`container mt-5 ${styles.additionalSection}`}>
//       <div className="row">
//         <div className="col-lg-6 col-md-12">
//           <div className={styles.sectionBadge}>OUR VISION</div>
//           <h2 className={`mt-1 ${styles.subHead}`}>
//             Turn your ideas into reality.
//           </h2>
//           <h3 className={`mt-2 ${styles.subHeadTwo}`}>
//             Capitalize on low hanging fruit to identify a ballpark value added
//             activity beta test. Override the digital divide with additional from
//             DevOps.
//           </h3>
//           <ul className="mt-3">
//             <li className={styles.aboutLi}>
//               <img src={tick} className={styles.tick} />
//               Bring to the table win-win survival strategies to ensure proactive
//               domination. At the end of the day.
//             </li>
//             <li className={styles.aboutLi}>
//               <img src={tick} className={styles.tick} /> Capitalize on low
//               hanging fruit to identify a ballpark value added activity to beta
//               test override the digital.
//             </li>
//           </ul>
//         </div>
//         <div className="col-lg-6 col-md-12">
//           {/* Placeholder for image or other content */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AboutUsVision;

import React, { useState, useEffect } from "react";
import styles from "./AboutDetail.module.css";
import tick from "./tick.png";
import client from "../sanityClient"; // Ensure this path is correct based on your project structure

const AboutUsVision = () => {
  const [visionData, setVisionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "aboutUsVision"][0] {
          sectionBadge,
          subHead,
          subHeadTwo,
          backgroundImage {
            asset->{
              url
            }
          },
          bulletPoints, // Ensure this matches your Sanity schema
        }`;
        const result = await client.fetch(query);
        if (result) {
          setVisionData(result);
        }
      } catch (error) {
        console.error("Error fetching About Us Vision data:", error);
      }
    };
    fetchData();
  }, []);

  if (!visionData) {
    return <div>Loading...</div>; // Handle loading state
  }

  const { sectionBadge, subHead, subHeadTwo, backgroundImage, bulletPoints } =
    visionData;

  // Handle case where backgroundImage is undefined or null
  const backgroundImageUrl = backgroundImage?.asset?.url || "";

  return (
    <div
      className={`container mt-5 ${styles.additionalSection}`}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }} // Dynamic background image
    >
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className={styles.badgeDivVision}>
            <div className={styles.sectionBadge}>{sectionBadge}</div>
          </div>
          <h2 className={`mt-1 ${styles.subHead}`}>{subHead}</h2>
          <h3 className={`mt-2 ${styles.subHeadTwo}`}>{subHeadTwo}</h3>
          <ul className="mt-3 px-0">
            {bulletPoints.map((point, index) => (
              <li key={index} className={styles.aboutLi}>
                <img src={tick} alt="tick icon" className={styles.tick} />
                {point} {/* Render each bullet point */}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-lg-6 col-md-12">
          {/* Placeholder for additional content */}
        </div>
      </div>
    </div>
  );
};

export default AboutUsVision;
