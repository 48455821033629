// import React from "react";
// import styles from "./Research.module.css";
// import logo from "./drone.png"; // Ensure you have the correct path to your logo

// const Research = () => {
//   return (
//     <div className={`${styles.researchContainer}`}>
//       <div className="row justify-content-center">
//         <div className="col-lg-12 col-sm-12 col-md-12 text-center">
//           <h1 className={styles.title}>Research & Development</h1>
//           <h2 className={styles.subtitle}>
//             Revolutionizing the Future Of Drones with
//           </h2>
//           <h3 className={styles.header}>DARUKA</h3>
//           <p className={styles.description}>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//             ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//             aliquip ex ea commodo consequat. Duis aute irure dolor in
//             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//             culpa.
//           </p>

//           <div className={` ${styles.imgDiv} w-100`}>
//             <img
//               src={logo}
//               alt="Research Logo"
//               className={`img-fluid ${styles.customImage}`}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Research;

// Research.js

import React, { useState, useEffect } from "react";
import styles from "./Research.module.css";
import client from "../../sanityClient";

const Research = () => {
  const [researchData, setResearchData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "research"][0] {
      title,
      subtitle,
      header,
      description,
      logo { asset->{url} }
    }`
      )
      .then((data) => {
        setResearchData(data);
        // console.log("research", data);
      })
      .catch(console.error);
  }, []);

  if (!researchData) return <div>Loading...</div>;

  return (
    <div className={`${styles.researchContainer}`}>
      <div className="row justify-content-center">
        <div className="col-lg-12 col-sm-12 col-md-12 text-center">
          <h1 className={styles.title}>{researchData.title}</h1>
          <h2 className={styles.subtitle}>{researchData.subtitle}</h2>
          <h3 className={styles.header}>{researchData.header}</h3>
          <p className={styles.description}>{researchData.description}</p>

          <div className={` ${styles.imgDiv} w-100`}>
            <img
              src={researchData.logo.asset.url}
              alt="Research Logo"
              className={`img-fluid ${styles.customImage}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;
