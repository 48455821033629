// import React from "react";
// import styles from "./AboutDetail.module.css";
// import aboutHistoryBg from "./aboutHistoryBg.png";

// const AboutUsHistory = () => {
//   return (
//     <div className={styles.historyAboutWrapper}>
//       <div className={styles.historyAboutBadge}>History</div>
//       <h2 className={styles.historyAboutHeading}>Our Journey So Far</h2>
//       <p className={styles.historyAboutDescription}>
//         We have been working tirelessly to achieve our mission and vision. Our
//         history is filled with numerous milestones and achievements that have
//         shaped our path to success.
//       </p>
//       <div className={styles.historyAboutImageWrapper}>
//         <img
//           src={aboutHistoryBg}
//           alt="History"
//           className={styles.historyAboutImage}
//         />
//         <div className={styles.topRightSquare}></div>
//         <div className={styles.bottomLeftSquare}></div>
//       </div>
//     </div>
//   );
// };

// export default AboutUsHistory;

import React from "react";
import styles from "./AboutDetail.module.css";
import aboutHistoryBg from "./aboutHistoryBg.png";

const AboutUsHistory = () => {
  return (
    <div className={styles.historyAboutWrapper}>
      <div className={styles.historyAboutBadge}> Our History</div>
      <h2 className={styles.historyAboutHeading}>
        Better Website Means A <br />
        User Experience
      </h2>
      <p className={styles.historyAboutDescription}>
        We have been working tirelessly to achieve our mission and vision. Our
        history is filled with numerous milestones and achievements that have
        shaped our path to success.
      </p>
      <div className={styles.historyAboutImageWrapper}>
        <img
          src={aboutHistoryBg}
          alt="History"
          className={styles.historyAboutImage}
        />
        <div className={styles.topRightSquare}></div>
        <div className={styles.bottomLeftSquare}></div>
      </div>
    </div>
  );
};

export default AboutUsHistory;
