// import React, { useState } from "react";
// import styles from "./Testimonial.module.css"; // Import module CSS
// import image from "./test-pic.jpg";
// import prev from "./prev.png";
// import next from "./next.png";

// const testimonialsData = [
//   {
//     id: 1,
//     name: "John Doe",
//     position: "CEO, Company ABC",
//     image: image, // Replace with actual image URL
//     text: "I am incredibly impressed with the transformative technology solutions provided by IndicArena. Their cutting-edge innovations have revolutionized our business operations, streamlining processes and boosting productivity. The seamless connectivity and intuitive user interface have made a significant impact on our team's efficiency. Thanks to their advanced data analytics capabilities, we now make data-driven decisions with confidence. I highly recommend IndicArena to any organization seeking to stay ahead in the digital era.",
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     position: "CTO, Company XYZ",
//     image: image,
//     text: "I am incredibly impressed with the transformative technology solutions provided by IndicArena. Their cutting-edge innovations have revolutionized our business operations, streamlining processes and boosting productivity. The seamless connectivity and intuitive user interface have made a significant impact on our team's efficiency. Thanks to their advanced data analytics capabilities, we now make data-driven decisions with confidence. I highly recommend IndicArena to any organization seeking to stay ahead in the digital era.",
//   },
//   {
//     id: 3,
//     name: "Alice Johnson",
//     position: "COO, Company 123",
//     image: image, // Replace with actual image URL
//     text: "I am incredibly impressed with the transformative technology solutions provided by IndicArena. Their cutting-edge innovations have revolutionized our business operations, streamlining processes and boosting productivity. The seamless connectivity and intuitive user interface have made a significant impact on our team's efficiency. Thanks to their advanced data analytics capabilities, we now make data-driven decisions with confidence. I highly recommend IndicArena to any organization seeking to stay ahead in the digital era.",
//   },
// ];

// const Testimonial = () => {
//   const [currentTestimonial, setCurrentTestimonial] = useState(0);

//   const nextTestimonial = () => {
//     setCurrentTestimonial((currentTestimonial + 1) % testimonialsData.length);
//   };

//   const prevTestimonial = () => {
//     setCurrentTestimonial(
//       currentTestimonial === 0
//         ? testimonialsData.length - 1
//         : currentTestimonial - 1
//     );
//   };

//   const { name, position, image, text } = testimonialsData[currentTestimonial];

//   return (
//     <div className={`container py-5 ${styles.testimonialContainer}`}>
//       <div className="text-center">
//         <h2 className={`mb-3 ${styles.header}`}>Testimonials</h2>
//         <p className={`mb-3 ${styles.subHeader}`}>
//           Trusted by <br />
//           over 4,000 customers worldwide
//         </p>
//       </div>

//       <div className={`card shadow-sm ${styles.testimonialCard}`}>
//         <div className="row g-0">
//           <div className="col-md-6 ">
//             <img
//               src={image}
//               className={`img-fluid rounded-start ${styles.testimonialImage}`}
//               alt={name}
//             />
//           </div>
//           <div className="col-md-6 ">
//             <div className={`card-body ${styles.cardBody}`}>
//               <p className={`card-text ${styles.text}`}>{text}</p>
//               <h5 className={`card-title ${styles.name}`}>{name}</h5>
//               <p className={` ${styles.position}`}>{position}</p>
//               <div className={styles.buttonGroup}>
//                 <button
//                   className={` ${styles.prevButton}`}
//                   onClick={prevTestimonial}
//                 >
//                   <img src={prev} alt="Prev Icon" className={styles.icon} />
//                 </button>
//                 <button
//                   className={` ${styles.nextButton}`}
//                   onClick={nextTestimonial}
//                 >
//                   <img src={next} alt="Next Icon" className={styles.icon} />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Testimonial;

import React, { useState, useEffect } from "react";
import client from "../../sanityClient"; // Import Sanity client
import imageUrlBuilder from "@sanity/image-url"; // For generating image URLs from Sanity
import styles from "./Testimonial.module.css"; // Import module CSS
import prev from "./prev.png";
import next from "./next.png";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Testimonial = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "testimonial"]{
          _id,
          name,
          position,
          image,
          text
        }`
      )
      .then((data) => {
        setTestimonialsData(data);
      })
      .catch(console.error);
  }, []);

  const nextTestimonial = () => {
    setCurrentTestimonial((currentTestimonial + 1) % testimonialsData.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial(
      currentTestimonial === 0
        ? testimonialsData.length - 1
        : currentTestimonial - 1
    );
  };

  if (!testimonialsData.length) return <div>Loading...</div>;

  const { name, position, image, text } = testimonialsData[currentTestimonial];

  return (
    <div className={`container py-5 ${styles.testimonialContainer}`}>
      <div className="text-center">
        <h2 className={`mb-3 ${styles.header}`}>Testimonials</h2>
        <p className={`mb-3 ${styles.subHeader}`}>
          Trusted by <br />
          over 4,000 customers worldwide
        </p>
      </div>

      <div className={`card shadow-sm ${styles.testimonialCard}`}>
        <div className="row g-0">
          <div className="col-md-6">
            <img
              src={urlFor(image).url()}
              className={`img-fluid rounded-start ${styles.testimonialImage}`}
              alt={name}
            />
          </div>
          <div className="col-md-6">
            <div className={`card-body ${styles.cardBody}`}>
              <p className={`card-text ${styles.text}`}>{text}</p>
              <h5 className={`card-title ${styles.name}`}>{name}</h5>
              <p className={` ${styles.position}`}>{position}</p>
              <div className={styles.buttonGroup}>
                <button
                  className={` ${styles.prevButton}`}
                  onClick={prevTestimonial}
                >
                  <img src={prev} alt="Prev Icon" className={styles.icon} />
                </button>
                <button
                  className={` ${styles.nextButton}`}
                  onClick={nextTestimonial}
                >
                  <img src={next} alt="Next Icon" className={styles.icon} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
