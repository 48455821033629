// import React from "react";
// import styles from "./Explore.module.css";

// const Explore = () => {
//   return (
//     <div className={`container-fluid ${styles.exploreContainer}`}>
//       <div className={styles.exploreImage}>
//         <div className={styles.content}>
//           <h1 className={styles.title}>Want to Explore More Of Us?</h1>
//           <p className={styles.subtitle}>
//             Visit Our Website - Indic Lancers <br />
//             to Get Yours now!
//           </p>
//           <a href="#discover" className={styles.button}>
//             www.indiclancers.com
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Explore;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./Explore.module.css";

const Explore = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "explore"]{
          title,
          subtitle,
          buttonText,
          buttonLink,
          backgroundImage {
            asset -> {
              _id,
              url
            }
          }
        }`
      )
      .then((data) => setContent(data[0]))
      .catch(console.error);
  }, []);

  if (!content) return <div>Loading...</div>;

  return (
    <div
      className={`container-fluid ${styles.exploreContainer}`}
      style={{ backgroundImage: `url(${content.backgroundImage.asset.url})` }}
    >
      <div className={styles.exploreImage}>
        <div className={styles.content}>
          <h1 className={styles.title}>{content.title}</h1>
          <p className={styles.subtitle}>{content.subtitle}</p>
          <a href={content.buttonLink} className={styles.button}>
            {content.buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Explore;
