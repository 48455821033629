// import React from "react";
// import styles from "./Card.module.css";

// const cardsData = [
//   {
//     title: "Flexible Work Environment",
//     description:
//       "Enjoy the freedom to work from anywhere in the world, whether it's the comfort of your home office or the excitement of a new city. Embrace a work-life balance that suits your needs.",
//   },
//   {
//     title: "Flexible Work Environment",
//     description:
//       "Enjoy the freedom to work from anywhere in the world, whether it's the comfort of your home office or the excitement of a new city. Embrace a work-life balance that suits your needs.",
//   },
//   {
//     title: "Flexible Work Environment",
//     description:
//       "Enjoy the freedom to work from anywhere in the world, whether it's the comfort of your home office or the excitement of a new city. Embrace a work-life balance that suits your needs.",
//   },
//   {
//     title: "Flexible Work Environment",
//     description:
//       "Enjoy the freedom to work from anywhere in the world, whether it's the comfort of your home office or the excitement of a new city. Embrace a work-life balance that suits your needs.",
//   },
//   {
//     title: "Flexible Work Environment",
//     description:
//       "Enjoy the freedom to work from anywhere in the world, whether it's the comfort of your home office or the excitement of a new city. Embrace a work-life balance that suits your needs.",
//   },
//   {
//     title: "Flexible Work Environment",
//     description:
//       "Enjoy the freedom to work from anywhere in the world, whether it's the comfort of your home office or the excitement of a new city. Embrace a work-life balance that suits your needs.",
//   },
// ];

// const Card = ({ title, description }) => (
//   <div className={` ${styles.cardFeature}`}>
//     <div className="card-body">
//       <h5 className={styles.cardTitle}>{title}</h5>
//       <hr />
//       <p className={styles.cardText}>{description}</p>
//     </div>
//   </div>
// );

// const Cards = () => (
//   <div className={` ${styles.containerFeat}`}>
//     <h1 className={styles.title}>Features</h1>
//     <div className={`row ${styles.rowCard}`}>
//       {cardsData.map((card, index) => (
//         <div key={index} className="col-md-6 col-lg-6 col-sm-12">
//           <Card title={card.title} description={card.description} />
//         </div>
//       ))}
//     </div>
//   </div>
// );

// export default Cards;

import React, { useEffect, useState } from "react";
import styles from "./Card.module.css";
import client from "../../sanityClient";

const Card = ({ title, description }) => (
  <div className={` ${styles.cardFeature}`}>
    <div className="card-body">
      <h5 className={styles.cardTitle}>{title}</h5>
      <hr />
      <p className={styles.cardText}>{description}</p>
    </div>
  </div>
);

const Cards = () => {
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "card"]{
          title,
          description
        }`
      )
      .then((data) => setCardsData(data))
      .catch(console.error);
  }, []);

  return (
    <div className={` ${styles.containerFeat}`}>
      <h1 className={styles.title}>Features</h1>
      <div className={`row ${styles.rowCard}`}>
        {cardsData.map((card, index) => (
          <div key={index} className="col-md-6 col-lg-6 col-sm-12">
            <Card title={card.title} description={card.description} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
