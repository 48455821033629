// import React from "react";
// import styles from "./About.module.css";
// import aboutIcon from "../../assets/about-us-icon.png";

// const About = () => {
//   return (
//     <div className={styles.containerAbout}>
//       <div className={` ${styles.rowContainer}`}>
//         <div className={` ${styles.logoCol}`}>
//           <img src={aboutIcon} alt="Logo" className={styles.logoAbout} />
//         </div>
//         <div className={`${styles.textCol}`}>
//           <div className={styles.aboutUsCol}>
//             <h2>About Us</h2>
//           </div>
//           <h1 className={styles.aboutTitle}>
//             Easy solutions
//             <br /> for your IT problems
//           </h1>
//           <div className={styles.contentDiv}>
//             <p className={styles.content}>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
//               enim ad minim veniam, quis nostrud exercitation ullamco laboris
//               nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
//               reprehenderit in voluptate velit esse cillum dolore eu fugiat
//               nulla pariatur. Excepteur sint occaecat cupidatat non proident,
//               sunt in culpa.
//             </p>
//           </div>

//           <div className={styles.learnMoreDiv}>
//             <button className={styles.learnMore}>Learn More</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default About;

import React, { useEffect, useState } from "react";
import styles from "./About.module.css";
import client from "../../sanityClient";

const About = () => {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "about"]{
        title,
        subtitle,
        description,
        buttonText,
        "imageUrl": image.asset->url
      }`
      )
      .then((data) => {
        setAboutData(data[0]);
        console.log("data", data);
      })
      .catch(console.error);
  }, []);

  if (!aboutData) return <div>Loading...</div>;

  return (
    <div className={styles.containerAbout}>
      <div className={` ${styles.rowContainer}`}>
        <div className={` ${styles.logoCol}`}>
          <img
            src={aboutData.imageUrl}
            alt="Logo"
            className={styles.logoAbout}
          />
        </div>
        <div className={`${styles.textCol}`}>
          <div className={styles.aboutUsCol}>
            <h2>{aboutData.title}</h2>
          </div>
          <h1 className={styles.aboutTitle}>
            {aboutData.subtitle.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <div className={styles.contentDiv}>
            <p className={styles.content}>{aboutData.description}</p>
          </div>
          <div className={styles.learnMoreDiv}>
            <button className={styles.learnMore}>{aboutData.buttonText}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
