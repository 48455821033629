import React from "react";
// import Navbar from "../Navbar/Navbar";
import HeroSection from "./HeroSection/HeroSection";
import About from "./about/About";
import Elempiad from "./Elempiad/Elempiad";
import Banner from "./banner/Banner";
import Research from "./Research/Research";
import Card from "./card/Card";
import Values from "./values/Values";
import Seen from "./seen/Seen";
import Experience from "./experience/Experience";
import Team from "./team/Team";
import Explore from "./explore/Explore";
import Testimonial from "./testimonial/Testimonial";
import Blog from "./blog/Blog";
import Faq from "./faq/Faq";
import Footer from "./footer/Footer";

const HomeComponents = () => {
  return (
    <div>
      <>
        <HeroSection />
        <About />
        <Elempiad />
        {/* <Banner /> */}
        <Research />
        <Card />
        <Values />
        <Seen />
        <Experience />
        <Team />
        <Explore />
        <Testimonial />
        <Blog />
        <Faq />
        <Footer />
      </>
    </div>
  );
};

export default HomeComponents;
