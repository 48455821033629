import React from "react";
import styles from "./Blogs.module.css";
import blogImg from "./blogImg.png";

const BlogPost = () => {
  return (
    <div className={styles.blogPost}>
      <div className={styles.row}>
        <div className={styles.imageCol}>
          <img src={blogImg} alt="Blog Post" className={styles.image} />
        </div>
        <div className={styles.contentCol}>
          <p className={styles.date}>By Admin - July 19, 2022</p>
          <h2 className={styles.heading}>
            The best design tools in 2022 for prototyping
          </h2>
          <p className={styles.description}>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
          <button className={styles.viewPostButton}>View Post</button>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
