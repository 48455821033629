import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"; // This includes Popper.js
import AboutDetail from "./components/aboutDetail/AboutDetail";
import Portfolio from "./components/portfolio/Portfolio";
import Contact from "./components/contactUs/Contact";
import Blogs from "./components/blogs/Blogs";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        {/* <Route path="home" element={<HomeHero />} /> */}
        <Route path="about" element={<AboutDetail />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="blogs" element={<Blogs />} />
        {/* <Route path="productdetails" element={<ProdDetail />} />
        <Route path="blogs" element={<BlogComponents />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="cookiesPolicy" element={<CookiesPolicy />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import HomeHero from "./components/home/Home-1/HomeHero";
// import HomeSec2 from "./components/home/Home-2/HomeSec2";
// import App from "./App";
// import HomeSec4 from "./components/home/Home-4/HomeSec4";
// import BlogComponents from "./components/blogs/BlogComponents";
// import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
// import CookiesPolicy from "./components/cookiesPolicy/CookiesPolicy";
// import ProdDetail from "./components/productdetails/ProdDetail";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Routes>
//         <Route index element={<App />} />
//         <Route path="home" element={<HomeHero />} />
//         <Route path="home2" element={<HomeSec2 />} />
//         <Route path="home4" element={<HomeSec4 />} />
//         <Route path="productdetails" element={<ProdDetail />} />
//         <Route path="blogs" element={<BlogComponents />} />
//         <Route path="privacyPolicy" element={<PrivacyPolicy />} />
//         <Route path="cookiesPolicy" element={<CookiesPolicy />} />
//       </Routes>
//     </BrowserRouter>
//   </React.StrictMode>
// );
