import React from "react";
import styles from "./AboutDetail.module.css"; // Import CSS Modules
import AboutUsVisionCard from "./AboutUsVisionCards";

const AboutUsValues = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.aboutUsValuesStat}>
        <div className={styles.statContent}>
          <div className={styles.statBadge}>
            <span className={styles.statBadgeText}>Our Values</span>
          </div>
          <h2 className={styles.statHeading}>Our core values & principles</h2>
          <p className={styles.statDescription}>
            Capitalize on low hanging fruit to identify a ballpark value added
            activity beta test. <br /> Override the digital divide with
            additional from DevOps.
          </p>
        </div>
        <button className={styles.statButton}>Get Started</button>
      </div>
      <AboutUsVisionCard />
    </div>
  );
};

export default AboutUsValues;
