// import React from "react";
// import styles from "./Seen.module.css";
// import seenLogo from "./seen-logo.png";
// import IconTwo from "./pngwing.png";
// // import IconThree from "./ht.png";
// import IconFour from "./i7.png";
// const Seen = () => {
//   return (
//     <div className={styles.seenContainer}>
//       <div className="container">
//         <h2 className={styles.title}>As Seen in</h2>
//         <div className={`row ${styles.logoRow}`}>
//           <div className={`col-lg-6 col-md-6 col-sm-12 ${styles.logoCol}`}>
//             <img src={seenLogo} alt="Logo 1" className={styles.logo} />
//           </div>
//           <div className={`col-lg-6 col-md-6 col-sm-12 ${styles.logoCol}`}>
//             <img src={IconTwo} alt="Logo 2" className={styles.logo} />
//           </div>
//           <div className={`col-lg-6 col-md-6 col-sm-12 ${styles.logoCol}`}>
//             <img src={seenLogo} alt="Logo 3" className={styles.logo} />
//           </div>
//           <div className={`col-lg-6 col-md-6 col-sm-12 ${styles.logoCol}`}>
//             <img src={IconFour} alt="Logo 4" className={styles.logo} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Seen;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./Seen.module.css";

const Seen = () => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "seen"]{
          title,
          "logos": logos[]{
            _key,
            alt,
            "url": asset->url
          }
        }`
      )
      .then((data) => setLogos(data[0]?.logos || []))
      .catch(console.error);
  }, []);

  return (
    <div className={styles.seenContainer}>
      <div className="container">
        <h2 className={styles.title}>As Seen in</h2>
        <div className={`row ${styles.logoRow}`}>
          {logos.map((logo) => (
            <div
              key={logo._key}
              className={`col-lg-2 col-md-6 col-sm-12 ${styles.logoCol}`}
            >
              <img src={logo.url} alt={logo.alt} className={styles.logo} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Seen;
