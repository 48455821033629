// import React, { useEffect, useState } from "react";
// import styles from "./HeroSection.module.css"; // Import the CSS module
// import icon1 from "../../assets/hero-img-1.png";
// import icon2 from "../../assets/hero-img-2.png";
// import client from "../../sanityClient";

// const HeroSection = () => {
//   const [heroData, setHeroData] = useState(null);
//   return (
//     <div className={styles["main-section"]}>
//       <div className={styles["hero-section"]}>
//         {/* Column 1 (Left Icon) */}
//         <div className={styles["column"]}>
//           <img src={icon1} alt="icon1" className={styles["icon1"]} />
//         </div>

//         {/* Column 2 (Text Content) */}
//         <div className={`${styles["column"]} ${styles["text-column"]}`}>
//           <div className={styles["hero-heading-div"]}>
//             <h1 className={styles["hero-heading"]}>
//               Developing <br />
//               Innovative <span className={styles["sol-text"]}>Solutions</span>
//             </h1>
//           </div>
//           <div className={styles["sub-title-div"]}>
//             <p className={styles["sub-title"]}>
//               Donec suscipit ante ipsum. Donec convallis quam at tortor
//               hendrerit, <br />
//               ut justo Donec ante ipsum.
//             </p>
//           </div>
//           <div className={styles["btn-section"]}>
//             <button className={styles["get-in-touch"]}>Get In Touch</button>
//             <button className={styles["discover"]}>Discover More</button>
//           </div>
//         </div>

//         {/* Column 3 (Right Icon) */}
//         <div className={styles["column"]}>
//           <img src={icon2} alt="icon2" className={styles["icon2"]} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;

import React, { useEffect, useState } from "react";
import styles from "./HeroSection.module.css"; // Import the CSS module
import client from "../../sanityClient";

const HeroSection = () => {
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "heroSection"][0]{
        heading,
        highlight,
        subtitle,
        button1Text,
        button2Text,
        "icon1Url": icon1.asset->url,
        "icon2Url": icon2.asset->url
      }`
      )
      .then((data) => {
        setHeroData(data);
      })
      .catch(console.error);
  }, []);

  if (!heroData) return <div>Loading...</div>;

  return (
    <div className={styles["main-section"]}>
      <div className={styles["hero-section"]}>
        <div className={styles["column"]}>
          <img
            src={heroData.icon1Url}
            alt="icon1"
            className={styles["icon1"]}
          />
        </div>
        <div className={styles["column"]}>
          <div className={styles["hero-heading-div"]}>
            <h1 className={styles["hero-heading"]}>
              {heroData.heading.split(" ")[0]} <br />
              {heroData.heading.split(" ").slice(1).join(" ")}{" "}
              <span className={styles["sol-text"]}>{heroData.highlight}</span>
            </h1>
          </div>
          <div className={styles["sub-title-div"]}>
            <p className={styles["sub-title"]}>{heroData.subtitle}</p>
          </div>
          <div className={styles["btn-section"]}>
            <button className={styles["get-in-touch"]}>
              {heroData.button1Text}
            </button>
            <button className={styles["discover"]}>
              {heroData.button2Text}
            </button>
          </div>
        </div>
        <div className={styles["column"]}>
          <img
            src={heroData.icon2Url}
            alt="icon2"
            className={styles["icon2"]}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
