// import React from "react";
// import styles from "./Experience.module.css";

// const Experience = () => {
//   return (
//     <div className={`w-100 img-fluid ${styles.experienceContainer}`}>
//       {/* Top right image */}
//       <div className={styles.topRightImage}></div>
//       {/* Bottom left image */}
//       <div className={styles.bottomLeftImage}></div>

//       {/* Content in between the images */}
//       <div className={styles.contentContainer}>
//         <div className="row">
//           {/* Left Column */}
//           <div className="col-md-4 col-sm-12">
//             <div className={styles.badge}>Experience</div>
//             <p className={styles.subHeader}>
//               More than 6 <br />
//               years of experience
//             </p>
//             <small className={styles.stat}>
//               3000+ our clients are insured around the World
//             </small>
//           </div>

//           {/* Right Column */}
//           <div className="col-md-8 col-sm-12">
//             <div className={styles.progressCircles}>
//               <div className={styles.progressCircle}>
//                 <div className={styles.circle}>
//                   <div className={styles.circleContent}>85%</div>
//                 </div>
//                 <p className={styles.circleTitle}>Consistency</p>
//               </div>
//               <div className={styles.progressCircle}>
//                 <div className={styles.circle}>
//                   <div className={styles.circleContent}>75%</div>
//                 </div>
//                 <p className={styles.circleTitle}>Reliability</p>
//               </div>
//               <div className={styles.progressCircle}>
//                 <div className={styles.circle}>
//                   <div className={styles.circleContent}>90%</div>
//                 </div>
//                 <p className={styles.circleTitle}>Quality</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Experience;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./Experience.module.css";

const Experience = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "experience"][0]{
          title,
          subHeader,
          stat,
          progressCircles[] {
            title,
            percentage
          }
        }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div className={`w-100 img-fluid ${styles.experienceContainer}`}>
      {/* Top right image */}
      <div className={styles.topRightImage}></div>
      {/* Bottom left image */}
      <div className={styles.bottomLeftImage}></div>

      {/* Content in between the images */}
      <div className={styles.contentContainer}>
        <div className="row">
          {/* Left Column */}
          <div className="col-md-4 col-sm-12">
            <div className={styles.badge}>{data.title}</div>
            <p className={styles.subHeader}>{data.subHeader}</p>
            <small className={styles.stat}>{data.stat}</small>
          </div>

          {/* Right Column */}
          <div className="col-md-8 col-sm-12">
            <div className={styles.progressCircles}>
              {data.progressCircles.map((circle, index) => (
                <div className={styles.progressCircle} key={index}>
                  <div className={styles.circle}>
                    <div className={styles.circleContent}>
                      {circle.percentage}
                    </div>
                  </div>
                  <p className={styles.circleTitle}>{circle.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
