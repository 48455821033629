import React from "react";
import styles from "./Elempiad.module.css";
import logo from "../../assets/elempiad-logo.png";
import mobLogo from "../../assets/mobile-logo.png";
import customIcon1 from "../../assets/up-arrow.png";
import playIcon from "../../assets/googleplay.png.png";
import appleIcon from "../../assets/appstore.png.png";

const Elempiad = () => {
  return (
    <div className={styles.elemContainer}>
      <div className={styles.heading}>
        <h1 className={styles["main-head"]}>Cool Things We’ve Made</h1>
        <h2 className={styles["sub-heading"]}>
          At IndicArena, we are driven by a strong set of values that shape
          every aspect of our business, ensuring transparency, ethical
          practices, and a shared
          <br /> commitment to making a positive impact.
        </h2>
      </div>
      <main className={styles.main}>
        <div className={styles["container-elem-main"]}>
          <div className={styles["left-col"]}>
            <div className={styles.header}>
              <img src={logo} alt="Logo" className={styles["logo-elem"]} />
              <h1 className={styles["title-elem"]}>Elympiad</h1>
            </div>
            <div className={styles["container-title-div"]}>
              <h2 className={styles["subtitleElem"]}>
                Elympiad -Quiz, Learn,
                <br /> Earn: Your Gateway to <br />
                Success!
              </h2>
            </div>
            <div className={styles.decDiv}>
              <p className={styles["description-elem"]}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>

            <div className={styles.buttons}>
              <button type="button" className={styles["btn-one"]}>
                Button Text
                <img
                  src={customIcon1}
                  alt="Custom Icon"
                  className={styles["custom-icon"]}
                />
              </button>
              <button type="button" className={styles["btn-one"]}>
                Button Text
                <img
                  src={customIcon1}
                  alt="Custom Icon"
                  className={styles["custom-icon"]}
                />
              </button>
            </div>
          </div>
          <div className={styles["right-col"]}>
            <img src={mobLogo} alt="Main" className={styles["main-image"]} />
            <div className={styles.icons}>
              <img src={playIcon} alt="Icon 1" className={styles.icon} />
              <img src={appleIcon} alt="Icon 2" className={styles.icon} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Elempiad;
