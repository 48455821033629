import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./Navbar.module.css";
import logo from "./logo.png";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    closeMobileMenu();
  };

  return (
    <nav className={`${styles.navbar} ${isMobileMenuOpen ? styles.fixed : ""}`}>
      <div className={styles.navbarLeft}>
        <Link to="/">
          <img src={logo} alt="Logo" className={styles.navbarLogo} />
        </Link>
      </div>
      <button
        className={styles.navbarToggler}
        onClick={toggleMobileMenu}
        aria-label="Toggle navigation"
      >
        <span className={styles.navbarTogglerIcon}></span>
      </button>
      <div
        className={`${styles.navbarRight} ${
          isMobileMenuOpen ? styles.active : ""
        }`}
      >
        <ul className={styles.navbarList}>
          <li className={styles.navbarItem}>
            <Link to="/" onClick={() => handleNavLinkClick("home")}>
              Home
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <Link to="/about" onClick={() => handleNavLinkClick("about")}>
              About Us
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <Link
              to="/products"
              onClick={() => handleNavLinkClick("ourProducts")}
            >
              Our Products
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <Link
              to="/portfolio"
              onClick={() => handleNavLinkClick("portfolio")}
            >
              Portfolio
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <Link to="/blogs" onClick={() => handleNavLinkClick("blogs")}>
              Blogs
            </Link>
          </li>
        </ul>
        <Link to="/contact-us" className={styles.contactButton}>
          Contact Us
        </Link>
      </div>
    </nav>
  );
}
