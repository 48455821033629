// import React from "react";
// import styles from "./Blog.module.css";
// import img1 from "./blog-img1.png";
// import img2 from "./blog-img2.png";

// const blogData = [
//   {
//     id: 1,
//     image: img1,
//     title: "Blog Title 1",
//     text: [
//       "Weve invested heavily in automation at Blend and Twingate is a powerful platform that allows us to programmatically deploy and maintain a zero trust approach to our infrastructure.",
//     ],
//     source: "Example.com",
//   },
//   {
//     id: 2,
//     image: img2,
//     title: "Blog Title 2",
//     text: [
//       "Weve invested heavily in automation at Blend and Twingate is a powerful platform that allows us to programmatically deploy and maintain a zero trust approach to our infrastructure.",
//     ],
//     source: "Example.com",
//   },
//   {
//     id: 3,
//     image: img1,
//     title: "Blog Title 3",
//     text: [
//       "Weve invested heavily in automation at Blend and Twingate is a powerful platform that allows us to programmatically deploy and maintain a zero trust approach to our infrastructure.",
//     ],
//     source: "Example.com",
//   },
//   {
//     id: 4,
//     image: img2,
//     title: "Blog Title 4",
//     text: [
//       "Weve invested heavily in automation at Blend and Twingate is a powerful platform that allows us to programmatically deploy and maintain a zero trust approach to our infrastructure.",
//     ],
//     source: "Example.com",
//   },
// ];

// const Blog = () => {
//   return (
//     <div className={`${styles.blogContainer} container-fluid`}>
//       {/* Title and button */}
//       <div>
//         <h2 className={styles.blogTitle}>
//           Securing the fastest growing <br /> companies in the world
//         </h2>
//         <div className={styles.btnDiv}>
//           <button className={styles.blogButton}>Read more blogs</button>
//         </div>
//       </div>

//       {/* Blog card carousel */}
//       <div className={styles.blogCardContainer}>
//         <div className={styles.blogCardList}>
//           {blogData.map((blog) => (
//             <div key={blog.id} className={styles.blogCard}>
//               <img src={blog.image} alt="Blog" />
//               <div className={styles.blogCardText}>
//                 {blog.text.map((paragraph, index) => (
//                   <p className={styles.blogText} key={index}>
//                     {paragraph}
//                   </p>
//                 ))}
//                 <div className={styles.blogCardSource}>
//                   Source * <span className={styles.sources}>{blog.source}</span>
//                 </div>
//               </div>
//             </div>
//           ))}
//           {/* Repeat the cards to ensure continuous loop */}
//           {blogData.map((blog) => (
//             <div key={blog.id} className={styles.blogCard}>
//               <img src={blog.image} alt="Blog" />
//               <div className={styles.blogCardText}>
//                 {blog.text.map((paragraph, index) => (
//                   <p className={styles.blogText} key={index}>
//                     {paragraph}
//                   </p>
//                 ))}
//               </div>
//               <div className={styles.blogCardSource}>
//                 Blend * <span className={styles.sources}>{blog.source}</span>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Blog;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./Blog.module.css";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "blog"]{
          _id,
          title,
          image{
            asset->{
              _id,
              url
            }
          },
          text,
          source
        }`
      )
      .then((data) => setBlogs(data))
      .catch(console.error);
  }, []);

  return (
    <div className={`${styles.blogContainer} container-fluid`}>
      {/* Title and button */}
      <div>
        <h2 className={styles.blogTitle}>
          Securing the fastest growing <br /> companies in the world
        </h2>
        <div className={styles.btnDiv}>
          <button className={styles.blogButton}>Read more blogs</button>
        </div>
      </div>

      {/* Blog card carousel */}
      <div className={styles.blogCardContainer}>
        <div className={styles.blogCardList}>
          {blogs.map((blog) => (
            <div key={blog._id} className={styles.blogCard}>
              <img src={blog.image.asset.url} alt="Blog" />
              <div className={styles.blogCardText}>
                {blog.text.map((paragraph, index) => (
                  <p className={styles.blogText} key={index}>
                    {paragraph.children[0].text}
                  </p>
                ))}
                <div className={styles.blogCardSource}>
                  Source * <span className={styles.sources}>{blog.source}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
