// import React from "react";
// import styles from "./AboutDetail.module.css";

// const AboutUsBanner = () => {
//   return (
//     <div className={styles.background}>
//       <div className={styles.overlay}>
//         <div className={styles.badge}>
//           <span className={styles.badgeText}>Services</span>
//         </div>
//         <h1 className="mt-2">
//           <span className={styles.subText}>
//             Creative Services For Boost <br />
//             Your Business Growth
//           </span>
//         </h1>
//       </div>
//     </div>
//   );
// };

// export default AboutUsBanner;

import React, { useState, useEffect } from "react";
// import client from "../../sanityClient";
import styles from "./AboutDetail.module.css";
import client from "../sanityClient";

const AboutUsBanner = () => {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "aboutUsBanner"][0] {
          badgeText,
          subText,
          backgroundImage {
            asset->{
              url
            }
          }
        }`;
        const result = await client.fetch(query);
        if (result) {
          setBannerData(result);
        }
      } catch (error) {
        console.error("Error fetching About Us Banner data:", error);
      }
    };
    fetchData();
  }, []);

  if (!bannerData) {
    return null; // Optionally handle loading state
  }

  const { badgeText, subText, backgroundImage } = bannerData;

  return (
    <div
      className={styles.background}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.overlay}>
        <div className={styles.badge}>
          <span className={styles.badgeText}>{badgeText}</span>
        </div>
        <h1 className="mt-2">
          <span className={styles.subText}>{subText}</span>
        </h1>
      </div>
    </div>
  );
};

export default AboutUsBanner;
