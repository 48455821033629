// import sanityClient from "@sanity/client";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

// import sanityCli from "../../yes/sanity.cli";

const client = sanityClient({
  projectId: "iwpfxt4j", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  token:
    "skldTFRPXJxQ5vo2BkJCLAfzML8MPKAyL4EuMkMeg143Pos3qjkMgqzIL1ZJXFU07UfSKUu8r1bMQS9VHHYYoW2U4WZ8e7Jk3nInTORmIHfSjrAigvuZJSkpgY1H1PumKTJ24ef8nAg3hND4MMUXYbYtV7q2w5VoWnoy9xXVc5x3jUQ3cfX6",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => {
  return builder.image(source);
};

export default client;
