import React from "react";
import Navbar from "../Navbar/Navbar";
import BlogPost from "./BlogPost";

const Blogs = () => {
  return (
    <div>
      <Navbar />
      <BlogPost />
      <BlogPost />
    </div>
  );
};

export default Blogs;
