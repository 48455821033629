// import React from "react";
// import styles from "./Values.module.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import IconOne from "./IconOne.png";

// const Values = () => {
//   const cards = [
//     {
//       id: 1,
//       logo: IconOne,
//       title: "Integrity",
//       description:
//         "Upholding honesty and ethical conduct in everything we do, building trust with our stakeholders.",
//     },
//     {
//       id: 2,
//       logo: IconOne,
//       title: "Innovation",
//       description:
//         "Upholding honesty and ethical conduct in everything we do, building trust with our stakeholders.",
//     },
//     {
//       id: 3,
//       logo: IconOne,
//       title: "Innovation",
//       description:
//         "Upholding honesty and ethical conduct in everything we do, building trust with our stakeholders.",
//     },
//     {
//       id: 4,
//       logo: IconOne,
//       title: "Innovation",
//       description:
//         "Upholding honesty and ethical conduct in everything we do, building trust with our stakeholders.",
//     },
//     {
//       id: 5,
//       logo: IconOne,
//       title: "Innovation",
//       description:
//         "Upholding honesty and ethical conduct in everything we do, building trust with our stakeholders.",
//     },
//     {
//       id: 6,
//       logo: IconOne,
//       title: "Innovation",
//       description:
//         "Upholding honesty and ethical conduct in everything we do, building trust with our stakeholders.",
//     },
//   ];

//   return (
//     <div className={styles.containerMain}>
//       <div className={` ${styles.badge}`}>Our Values</div>
//       <div className={`${styles.textDiv}`}>
//         <h1 className={styles.heading}>Guided by Integrity and Purpose</h1>
//         <p className={`${styles.description} text-center`}>
//           At IndicArena, we are driven by a strong set of values that shape
//           every aspect of our business, ensuring transparency, <br />
//           ethical practices, and a shared commitment to making a positive
//           impact.
//         </p>
//       </div>

//       <div className={`row ${styles.cardValues}`}>
//         {cards.map((card, index) => (
//           <div key={card.id} className={`col-md-4 ${styles.cardContainer}`}>
//             <img src={card.logo} alt={card.title} />
//             <h2 className={styles.cardTitle}>{card.title}</h2>
//             <div className={styles.decContainer}>
//               <p className={styles.cardDescription}>{card.description}</p>
//               {(index + 1) % 3 !== 0 && (
//                 <div className={styles["hr-vertical"]} />
//               )}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Values;

import React, { useEffect, useState } from "react";
import client, { urlFor } from "../../sanityClient";

import styles from "./Values.module.css";

const Values = () => {
  const [introContent, setIntroContent] = useState({
    badge: "",
    heading: "",
    description: "",
  });
  const [values, setValues] = useState([]);

  useEffect(() => {
    client
      .fetch(
        '*[_type == "valuesPage"]{introBadge, introHeading, introDescription, values}'
      )
      .then((data) => {
        if (data && data.length > 0) {
          const valuesPage = data[0];
          setIntroContent({
            badge: valuesPage.introBadge,
            heading: valuesPage.introHeading,
            description: valuesPage.introDescription,
          });
          setValues(valuesPage.values);
        }
      })
      .catch(console.error);
  }, []);

  return (
    <div className={styles.containerMain}>
      <div className={` ${styles.badge}`}>{introContent.badge}</div>
      <div className={`${styles.textDiv}`}>
        <h1 className={styles.heading}>{introContent.heading}</h1>
        <p className={`${styles.description} text-center`}>
          {introContent.description}
        </p>
      </div>

      <div className={`row ${styles.cardValues}`}>
        {values.map((value, index) => (
          <div key={index} className={`col-md-4 ${styles.cardContainer}`}>
            <img src={urlFor(value.logo)} alt={value.title} />
            <h2 className={styles.cardTitle}>{value.title}</h2>
            <div className={styles.decContainer}>
              <p className={styles.cardDescription}>{value.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Values;
