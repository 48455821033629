import React, { useState, useEffect } from "react";
// import imageUrlBuilder from "@sanity/image-url";
import client from "../sanityClient";
import styles from "./ContactUs.module.css";
import { v4 as uuid4 } from "uuid";

// const builder = imageUrlBuilder(client);

const ContactUsForm = () => {
  const [content, setContent] = useState(null);

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "", // Initialize emailId field
    subject: "", // Initialize phoneNumber field
    description: "", // Initialize companyName field
    message: "",
  });

  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await client.fetch(`*[_type == "contactForm"][0]`);
        setContent(response);
      } catch (error) {
        console.error("Error fetching contact form config:", error);
      }
    };

    fetchFormConfig();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content) return;

    try {
      const response = await client
        .patch(content._id)
        .setIfMissing({ formSubmissions: [] })
        .append("formSubmissions", [
          {
            ...formData,
            _key: uuid4(),
            submittedAt: new Date().toISOString(),
          },
        ])
        .commit();

      if (response) {
        console.log("Form submitted successfully!", formData);
        // Reset form fields after successful submission
        setFormData({
          fullName: "",
          companyName: "",
          description: "",
          subject: "",
          message: "",
        });
      } else {
        console.error("Failed to submit form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  if (!content || !content.formLabels) {
    return <p></p>;
  }

  return (
    <div className={styles.contactUsContainer}>
      <div className={styles.banner}>
        <h2 className={styles.headerForm}>{content.heading}</h2>
      </div>
      <div className={styles.contactFormWrapper}>
        <form
          className={`col-lg-6 ${styles.contactForm}`}
          onSubmit={handleSubmit}
        >
          <div className={styles.formRow}>
            <label className={styles.labelText} htmlFor="fullName">
              {content.formLabels.fullName}
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              // placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
              required
              className={styles.inputArea}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.labelText} htmlFor="emailId">
              {content.formLabels.companyName}
            </label>
            <input
              type="email"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
              className={styles.inputArea}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.labelText} htmlFor="subject">
              {content.formLabels.subject}
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              // placeholder="Enter"
              value={formData.subject}
              onChange={handleChange}
              required
              className={styles.inputArea}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.labelText} htmlFor="description">
              {content.formLabels.description}
            </label>
            <input
              type="text"
              id="description"
              name="description"
              // placeholder="Enter your desc"
              value={formData.description}
              onChange={handleChange}
              className={styles.inputArea}
            />
          </div>
          <div className={styles.textAreaRow}>
            <label className={styles.labelText} htmlFor="message">
              {content.formLabels.message}
            </label>
            <textarea
              id="message"
              name="message"
              // placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}
              required
              className={styles.textArea}
            />
          </div>
          <div className={styles.formBtnRow}>
            <button type="submit" className={styles.sendMessageButton}>
              {content.submitButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUsForm;
